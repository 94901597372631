import * as React from "react"
import Clientes from "../components/commons/clientes"
import Image from "../images/illustracion/home.svg";
import Layout from "../components/layout/en"
import { Link } from "gatsby";
import { graphql } from 'gatsby'
import Portfolio from "../components/home/portfolio"
import Testimonios from "../components/home/testimonios"

// markup
const IndexPage = (props) => {

  return (
    <Layout transparent location={props.location} pageId="home" metaTitle="Web development in Madrid: Wordpress, Drupal, React | Crequs">
      <section className="main-banner-v3" style={{backgroundImage:'url(/img/hero-bg.jpg)'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="banner-txt">
                <h2>Web development<br />in Madrid</h2>
                <span>EXPERTS IN WORDPRESS, DRUPAL AND REACT</span>
                <a href="mailto:info@crequs.com" title="" className="btn-default3" >Contact us</a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <Image />
            </div>
          </div>
        </div>
      </section>
      <section className="about_v1_sec">
        <div className="container">
          <div className="about_v1_content">
            <p>We are an <span>international multidisciplinary</span> team with over 10 years’ experience working in web development. Our three priorities when 
              undertaking any project are: scalability, maintainability and incremental development.</p>
            <h4>We work with and for marketing agencies, SMEs, freelancers and government bodies.</h4>
          </div>
          <div className="resume_details">
            <div className="resume_mile row">
              <div className="col-lg-3">
                <h3 className="rs-heading">services</h3>
              </div>
              <div className="col-lg-9">
                <div className="rs_info">
                  <p>
                  Here you can find a list of our main services. We can develop in both PHP and Javascript. Likewise, we are experts in <strong>Wordpress</strong> 
                  and <strong>Drupal</strong> given the fact we’ve been working with these tools for well over a decade.</p>
                  <div className="row">
                    <div className="col-md-4">
                      <ul className="lniks">
                        <li>WEB DEVELOPMENT IN DRUPAL</li>
                        <li>WEB DEVELOPMENT IN WORDPRESS</li>
                        <li>ON-PAGE SEO OPTIMISATION</li>
                        <li>WEB DEVELOPMENT IN REACT</li>
                        <li>WEB SCRAPIN</li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul className="lniks">
                        <li>WEB PAGE OPTIMISATION</li>
                        <li>HEADLESS CMS</li>
                        <li>API INTEGRATION</li>
                        <li>GOOGLE TAG MANAGER</li>
                        <li>ACTIVECAMPAIGN API</li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul className="lniks">
                        <li>INTEGRATION WITH CRM</li>
                        <li>WEB ANIMATION</li>
                        <li>Mailchimp API</li>
                        <li>PAYMENT METHODS INTEGRATION</li>
                        <li>TAILORMADE PLUGINS AND MODULES</li>
                      </ul>
                    </div>
                  </div>
                  <p style={{marginTop:'60px'}}><Link to="/en/services" style={{color:'#dd2569'}}>See all our services &rarr;</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Portfolio trabajos={props.data.allContentfulPortfolio.nodes} lang="en" />
      <Clientes clientes={props.data.allContentfulClientes.nodes} />
      <Testimonios testimonios={props.data.allContentfulTestimonios.nodes} />
    </Layout>
  )
}

export default IndexPage


export const pageQuery = graphql`
{
  allContentfulClientes(limit: 100, sort: {order: ASC, fields: createdAt}, filter:{node_locale:{eq:"en"}}) {
    nodes {
      title
      id
      logo {
        gatsbyImageData(layout: CONSTRAINED, width: 150, quality:100)
      }
    }
  }
  allContentfulPortfolio(filter: {node_locale: {eq: "en"}}) {
    nodes {
      id
      title
      web
      tieneFicha
      slug
      image{
				gatsbyImageData(layout: CONSTRAINED, width: 600, quality:60)
      }
      texto {
        raw
      }
      tipoDeTrabajo{
				id
        name
      }
      
    }
  }
  allContentfulTestimonios(sort: { fields: [createdAt], order: DESC }, filter: {node_locale: {eq: "en"}}) {
    nodes {
      nombre
      cargo
      id
      imagen {
        gatsbyImageData(layout: FIXED, width: 70, quality: 70)
      }
      texto {
        raw
      }
    }
  }
}
`
